import { auth } from "matchi-auth";
import { T, keycloakToMatchiLocale, useT } from "i18n";
import { ArticleRow, PriceDetailRow, toast } from "@/components";
import { getPaymentOptionsQuery, getPaymentServiceQuery } from "@/queries";
import { formatCurrency } from "format-numbers";
import { AnimatePresence, motion } from "framer-motion";
import {
  getUnapplyPromoCodeMutation,
  checkoutResponse,
  getCheckoutQuery,
  getUnapplyValueCardMutation,
  giftCard,
  promoCodeOutcome,
} from "matchi-api";
import { Icon, Image, Loading } from "ui";
import { useMutation } from "@tanstack/react-query";
import { useParams, useRevalidator } from "react-router-dom";
import { queryClient } from "@/queryClient";
import { useMeasure } from "react-use";
import { forwardRef, useState } from "react";
import { usePaymentLoadingStore } from "@/paymentLoadingStore";
import { Dialog } from "./Dialog";

interface OrderSummaryProps {
  checkout: checkoutResponse;
}

export const OrderSummary = ({ checkout }: OrderSummaryProps) => {
  const locale = keycloakToMatchiLocale(auth.getLocale());
  const { revalidate } = useRevalidator();
  const { token } = useParams();
  const [ref, { height }] = useMeasure<HTMLDivElement>();
  const [priceDetailsOpen, setPriceDetailsOpen] = useState(false);

  const onUnapplySuccess = async () => {
    const checkout = await queryClient.fetchQuery(getCheckoutQuery(token!));
    const paymentService = await queryClient.fetchQuery(
      getPaymentServiceQuery(token!),
    );

    const { coupons } = checkout.paymentMethods;
    await queryClient.fetchQuery(
      getPaymentOptionsQuery(coupons, paymentService),
    );

    revalidate();
  };

  return (
    <div className="border-default overflow-hidden rounded-xl border">
      <header className="border-default flex items-center space-x-3 border-b bg-gray-200 px-4 py-3">
        <div className="h-6 w-6 overflow-hidden rounded-md">
          <Image
            className="w-full rounded-md"
            src={checkout.facilityImageUrl}
            alt="logo"
          />
        </div>
        <h2 className="font-medium leading-none">{checkout.facilityName}</h2>
      </header>
      <ul>
        {checkout.articles.map((article, index) => (
          <li key={index}>
            <ArticleRow
              timeZone={checkout.facilityTimeZone!}
              article={article}
            />
          </li>
        ))}
      </ul>
      <AnimatePresence>
        {checkout.promoCodeOutcomes.length &&
          checkout.promoCodeOutcomes.map(promoCode => (
            <motion.div
              key={promoCode.promoCode}
              initial={{ opacity: 0, height: 0, overflow: "hidden" }}
              animate={{ opacity: 1, height, overflow: "visible" }}
              exit={{ opacity: 0, height: 0, overflow: "hidden" }}
              transition={{ duration: 0.25 }}
            >
              <PromoCodeRow
                ref={ref}
                token={token!}
                promoCode={promoCode}
                locale={locale}
                currency={checkout.price?.currency!}
                onSuccess={onUnapplySuccess}
              />
            </motion.div>
          ))}
        {checkout.valueCardOutcomes.length &&
          checkout.valueCardOutcomes.map(({ valueCard, amount }) => (
            <motion.div
              key={valueCard.id}
              initial={{ opacity: 0, height: 0, overflow: "hidden" }}
              animate={{ opacity: 1, height, overflow: "visible" }}
              exit={{ opacity: 0, height: 0, overflow: "hidden" }}
              transition={{ duration: 0.25 }}
            >
              <ValueCardRow
                ref={ref}
                token={token!}
                valueCard={valueCard}
                consumedAmount={amount}
                locale={locale}
                onSuccess={onUnapplySuccess}
              />
            </motion.div>
          ))}
      </AnimatePresence>

      <div className="flex justify-between p-4">
        <span className="text-lg font-medium">
          <T _str="Total" />
        </span>
        <span className="text-theme-accent text-lg font-medium">
          {formatCurrency({
            locale,
            number: parseFloat(checkout.amountToPay),
            currency: checkout.price?.currency!,
          })}
        </span>
      </div>
      <div className="flex flex-1 bg-gray-200 p-4 border-t border-default">
        <button
          onClick={() => setPriceDetailsOpen(true)}
          className="flex flex-1 justify-between items-center font-medium"
        >
          <span>
            <T _str="Price details" />
          </span>
          <Icon icon="ChevronRightSolid" className="w-4" />
        </button>
        <Dialog
          open={priceDetailsOpen}
          onClose={() => setPriceDetailsOpen(false)}
          title={<T _str="Price details" />}
        >
          <ul>
            {checkout.articles.map((article, index) => (
              <li key={index}>
                <PriceDetailRow article={article} />
              </li>
            ))}
          </ul>
        </Dialog>
      </div>
    </div>
  );
};

interface PromoCodeRowProps {
  promoCode: promoCodeOutcome;
  token: string;
  currency: string;
  locale: MatchiLocale;
  onSuccess?: () => void;
}

const PromoCodeRow = forwardRef<HTMLDivElement, PromoCodeRowProps>(
  ({ promoCode, token, currency, locale, onSuccess }, ref) => {
    const { verifyingRedirectResult } = usePaymentLoadingStore(state => ({
      verifyingRedirectResult: state.verifyingRedirectResult,
    }));
    const t = useT();
    const unapplyPromoCode = useMutation({
      ...getUnapplyPromoCodeMutation(),
      onSuccess,
      onError: () => {
        toast({
          title: t("Could not unapply promo code"),
          description: t("Please try again later."),
        });
      },
    });

    return (
      <div ref={ref}>
        <div className="border-default flex items-center justify-between space-x-3 border-b p-4">
          <div className="flex gap-x-2">
            <span className="font-medium">{promoCode.promoCode}</span>
            <button
              disabled={unapplyPromoCode.isLoading || verifyingRedirectResult}
              className="disabled:text-gray-1100 flex items-center gap-x-2 font-medium underline transition-colors"
              onClick={() => unapplyPromoCode.mutate({ token })}
            >
              <T _str="Remove" />
              {unapplyPromoCode.isLoading && <Loading size="sm" />}
            </button>
          </div>
          <span className="font-medium">
            -
            {formatCurrency({
              locale,
              currency,
              number: parseFloat(promoCode.amount!),
            })}
          </span>
        </div>
      </div>
    );
  },
);

interface ValueCardRowProps {
  valueCard: giftCard;
  consumedAmount: number;
  token: string;
  locale: MatchiLocale;
  onSuccess?: () => void;
}

const ValueCardRow = forwardRef<HTMLDivElement, ValueCardRowProps>(
  ({ valueCard, consumedAmount, token, locale, onSuccess }, ref) => {
    const { verifyingRedirectResult } = usePaymentLoadingStore(state => ({
      verifyingRedirectResult: state.verifyingRedirectResult,
    }));
    const t = useT();
    const unapplyValueCard = useMutation({
      ...getUnapplyValueCardMutation(),
      onSuccess,
      onError: () => {
        toast({
          title: t("Could not unapply value card"),
          description: t("Please try again later."),
        });
      },
    });

    return (
      <div ref={ref}>
        <div className="border-default flex items-center justify-between space-x-3 border-b p-4">
          <div className="flex gap-x-2">
            <span className="font-medium">{valueCard.name}</span>
            <button
              disabled={unapplyValueCard.isLoading || verifyingRedirectResult}
              className="disabled:text-gray-1100 flex items-center gap-x-2 font-medium underline transition-colors"
              onClick={() =>
                unapplyValueCard.mutate({
                  token,
                  valueCardId: valueCard.id!,
                })
              }
            >
              <T _str="Remove" />
              {unapplyValueCard.isLoading && <Loading size="sm" />}
            </button>
          </div>
          <span className="font-medium">
            -
            {formatCurrency({
              locale,
              currency: valueCard.currency,
              number: consumedAmount,
            })}
          </span>
        </div>
      </div>
    );
  },
);
